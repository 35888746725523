#confirmation-sync-wrapper {
    min-height: 80vh;
    width: 100vw;
    background-color: #222;
    display: flex;
    justify-content: center;
    align-items: center;
}
#confirmation-sync {
    position: relative;
    padding: 3em 1em;
    background: #111;
    overflow: hidden;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
#confirmation-sync img {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    overflow: hidden;
    margin: 2em 1em;
}
#confirmation-sync h5 {
    color: white;
    font-family: Roboto, serif;
}