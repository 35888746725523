#nav-logo-admin {
    font-family: 'Audiowide', serif !important;
    font-weight: bold;
    font-size: 16px !important;
    display: flex;
    text-decoration: none;
    align-items: center;
    color: #ff254e;
}

#nav-logo-admin > img {
    height: 1.5em;
    width: 1.5em;
    border-radius: 50%;
}

.icon{
    min-width: 36px !important;
}

#admin-user-pagination{
    margin-top: 25px;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
}

.clickable-id {
    cursor: pointer;
    text-decoration: underline;
    color: lightblue !important;
    font-weight: bold;
}