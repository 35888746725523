#main-servers {
  background-color: #222;
  display: grid;
  grid-template-columns: repeat(3, 30%);
  grid-column-gap: 5%;
  flex-wrap: wrap;
  justify-content: center;
  width: 50%;
  margin: 3em auto;
  min-height: 400px;
}
.entry-username {
  font-family: 'Audiowide', sans-serif !important;
}
#main-starshine-header {
  background-color: #333;
  min-height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
#main-starshine-header-bottom-triangle {
  min-height: 15vh;
  background-color: #333;
  -webkit-clip-path: polygon(25% 7%, 75% 7%, 100% 50%);
  clip-path: polygon(0% 0%, 100% 0%, 50% 100%);
}
#nav-logo {
  transition: 0.3s;
  padding: 4px 8px;
  border-radius: 8px;
}
#nav-logo:hover {
  background-color: rgba(255, 255, 255, 0.1);
}
#project-description {
  width: 20vw;
  color: whitesmoke;
  text-shadow: 1px 1px 3px black;
}
#project-description p {
  color: #bbbbbb;
}
#project-image-wrapper {
  width: 35vw;
}

#community-tops {
  display: flex;
  margin: auto;
  width: 65%;
}
#total-playing-top,
#total-donation-top {
  min-height: 400px;
  position: relative;
  flex: 1;
  margin: 0 1em;
}
#total-playing-top > h2,
#total-donation-top > h2 {
  text-align: center;
  color: whitesmoke;
  font-family: 'Roboto', serif;
}
#total-donation-top {
  flex: 1;
}

.player-top-entry {
  background-color: #444;
  margin: 3px;
  display: flex;
  transition: 0.4s;
  cursor: pointer;
  font-family: Roboto, serif;
}
.project-introduction {
  font-size: 14px;
}
.project-introduction-cringe {
  font-size: 14px;
  /*text-decoration: line-through;*/
}
#main-starshine-header-bottom-triangle h5 {
  padding-top: 10px;
  color: white;
  font-family: Roboto, serif !important;
}
.player-top-entry:hover {
  background-color: #555;
}
.player-top-entry:hover > .entry-username {
  color: gold;
}
.player-top-entry > img {
  flex-basis: 50px;
  width: 50px;
  height: 50px;
  object-fit: cover;
  padding: 2px;
}
.player-top-entry > .entry-username {
  overflow: hidden;
  flex: 3;
  height: 50px;
  line-height: 50px;
  text-transform: uppercase;
  font-style: italic;
  font-weight: bold;
  color: #eee;
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
  font-size: 18px;
  padding: 2px 1em;
}
.player-top-entry > .entry-meta {
  flex: 1;
  line-height: 50px;
  color: #ddd;
  font-size: 18px;
  text-align: right;
  padding: 2px 1em 2px 2px;
  text-decoration: underline;
  text-underline: gold;
}

/*noinspection CssInvalidPropertyValue*/
#project-image-wrapper img {
  width: 100%;
  image-rendering: -webkit-optimize-contrast;
}

#discord-description {
  flex: 1;
  flex-basis: 300px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

@media screen and (max-width: 1780px) {
  #main-servers {
    width: 65%;
  }

}
@media screen and (max-width: 1280px) {
  #main-servers {
    width: 90%;
  }
  #play-button-wrapper {
    justify-content: center;
  }
  #main-starshine-header {
    min-height: 30vh;
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
  #main-starshine-header-bottom-triangle {
    min-height: 10vh;
  }
  #project-description {
    width: 80%;
    text-align: center;
  }
  #project-image-wrapper {
    width: 50%;
  }
  #community-tops {
    width: 100%;
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 720px) {
  #main-servers {
    border-radius: 0 !important;
    width: 100%;
    grid-template-columns: repeat(1, 100%);
    grid-row-gap: 1%;
    grid-column-gap: 5%;
    margin-top: 0 !important;
  }
  #discord-description {
    flex: 1;
    flex-basis: 300px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: none;
  }
  #main-starshine-header-bottom-triangle {
    clip-path: none;
    min-height: 1px;
  }
  #main-starshine-header-bottom-triangle h5 {
    padding: 10px !important;
    background: #222;
    margin: 1px 0;
  }
  #main-starshine-header {
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
  #project-description {
    width: 80%;
    text-align: center;
  }
  #project-image-wrapper {
    width: 90%;
  }
  #community-tops {
    width: 100%;
    flex-wrap: wrap;
  }
  #total-playing-top,
  #total-donation-top {
    flex: 1;
    margin: 0;
  }
  .player-top-entry > .entry-username {
    font-size: 18px;
    padding: 2px 1em;
  }
  #total-playing-top > h1,
  #total-donation-top > h1 {
    font-size: 18px;
  }
}
