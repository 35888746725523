#items-management {
    display: flex;
    flex-wrap: wrap;
    background-color: #1a1a1a;
    position: relative;
}

#balance-toast {
    background-color: #222;
    color: whitesmoke;
    min-width: 300px;
    z-index: 10;
    position:fixed;
    right: 0;
    bottom: 0
}
#active-product-content-list {
    font-family: 'Roboto', sans-serif;
    font-weight: 100;
    overflow-y: scroll;
    max-height: 400px;
}
#balance-toast > .toast-body {

}

#balance-toast > .toast-header {
    background-color: #111;
    color: gold;
}

#balance-message {
    padding-left: 4px;
    border-left: 3px solid gold;
}

#balance-toast img {
    width: 25px;
    height: 25px;
    border-radius: 20px !important;
    overflow: hidden
}

#items-filter {
    color: white;
    position: relative;
    background-color: #141414;
    overflow: hidden;
    flex: 0 0 80px;
    overflow-y: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

#items-filter::-webkit-scrollbar {
    display: None;
}

#servers-filter {
    width: 100%;
    transition: 0.3s;
}

#mods-filter {
    position: absolute;
    width: 100%;
    top: 0;
    left: 100%;
    transition: 0.3s;
}

#mods-filter > #back-to-mods {
    padding: 4px 0;
    transition: 0.4s;
    margin: auto;
    text-align: center;
    background-color: #111;
    color: gold;
    border-bottom: 1px solid gold;
    cursor: pointer
}

#back-to-mods:hover {
    color: white
}

#items-list {
    position: relative;
    background-color: #222;
    height: 90vh;
    flex: 5 0 0;
    overflow-y: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

#items-list::-webkit-scrollbar {
    display: None;
}
.product-card-button {
    cursor: pointer;
    font-family: "Roboto";
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: gold;
    border: none;
    padding: 2px 8px;
    font-size: 13px;
    border-radius: 6px;
    transition: 0.1s;
    margin: auto;
}
.product-card-button img {
    height: 1em;
    margin-left: 3px;
    filter: drop-shadow(3px 3px 4px blue);
}
#items-list-inner {
    display: flex;
    position: relative;
    min-height: 50vh;
    flex-wrap: wrap;
    justify-content: stretch;
}

#item-detail {
    position: relative;
    background-color: #333;
    margin: 0 0 0 24px;
    flex: 1 0 17.5%;
    min-height: 90vh;
    display: flex;
    flex-direction: column;
    color: #eee;
}

#item-detail > #item-heading {
    text-align: center;
}

#item-detail > hr {
    background-color: #fff;
}

#modal-product-description-body > ul > li, #product-detail-meta > ul > li {
    display: flex;
}

#modal-product-content-list, #product-detail-meta > ul {
    list-style: none;
    text-align: left;
    max-height: 400px;
    overflow-y: scroll;
    padding: 0 0 0 8px;
}

#modal-product-content-list > li > img, #product-detail-meta > ul > li > img {
    height: 1em;
    margin: 0.25em;
    max-width: 1em;
}

.hexagon {
    clip-path: polygon(25% 7%, 75% 7%, 100% 50%, 75% 93%, 25% 93%, 0% 50%);
}

.item-card {
    max-width: 80px;
    background-color: #111;
    margin: 2px;
    border: 2px solid #333;
    text-align: center;
    flex: 1 0 70px;
    height: auto;
    padding: 8px;
    transition: 0.5s;
    cursor: pointer;
}

.item-card > img {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}

.item-card:hover > img {
    background-color: rgba(255, 255, 255, 0.5);
}

.item-card[active=true] {
    background-color: rgba(255, 255, 0, 0.9);
}

.item-card:before {
    content: '';
    float: left;
    padding-top: 100%;
}

.active-filter {
    background-color: gold !important;
}
.filter {
    margin: 8px auto 8px auto;
    background: #222;
    width: 60px;
    height: 60px;
    position: relative;
    transition: 0.25s;
    cursor: pointer;
}

.filter > div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    height: 80%;
    background-color: #333
}

.filter[active="true"] {
    background-color: gold
}

.filter div {
    transition: 0.2s;
}

.filter:hover div {
    background-color: rgba(255, 255, 255, 0.2);
}

.filter > div > img {
    width: 75%;
    height: 75%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%)
}

#items-loading {
    position: absolute;
    top: 30vh;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    margin: auto;
    transition: 0.5s;
}

.empty-item-card {
    visibility: hidden;
}

#items-filters-tab {
    color: white;
    display: flex;
    padding: 4px 8px;
}

#items-filters-tab > label {
    flex-grow: 1;
    margin: 8px 0 0 0;
}

#items-filters-tab > label > input[type='checkbox'] {
    margin: 0 8px;
}

.product-list {
    display: grid;
    grid-template-columns: repeat(20, 12.5%);
    overflow-x: auto;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: thin; /* Firefox */
    grid-auto-rows: minmax(min-content, max-content);
    padding: 4px;
    width: 100%;
}

.product-card {
    transition: 0.1s;
    background-color: #333;
    margin: 2px;
    cursor: pointer;
    position: relative;
    scrollbar-width: thin;
}
.product-card-selected {
    border: 2px solid gold;
}
.product-list::-webkit-scrollbar {
    background-color: red;
    color: whitesmoke;
}
.product-list::-webkit-scrollbar-track {
    background: #333;
    height: 8px;
}
.product-list::-webkit-scrollbar-thumb {
    background-color: whitesmoke;    /* color of the scroll thumb */
    border-radius: 20px;
    height: 4px;/* roundness of the scroll thumb */
    border: 3px solid #222;  /* creates padding around scroll thumb */
}

.product-card > .remove-from-cart-button {
    position: absolute;
    right: 4px;
    top: 4px;
    width: 1em;
    height: 1em;
    text-align: center;
    line-height: 0.75em;
    visibility: hidden;
    transition: 0.2s;
    border-radius: 50%;
}

.product-card:hover > .remove-from-cart-button {
    visibility: visible;
}

.product-card > .remove-from-cart-button:hover {
    background-color: orangered;
}

.product-card[active=true] {
    border: 2px solid gold;
}

.product-card:hover {
    background-color: #3a3a3a;
}

.product-image-wrapper {
    flex-basis: 0;
    margin: 6px;
    background-color: #495057;
}

.product-image-wrapper > img {
    image-rendering: pixelated;
    filter: drop-shadow(2px 4px 6px black);
    padding: 15%;
    width: 100%;
    font-size: 8px;
}

@-moz-document url-prefix() {
    .product-image-wrapper > img {
        image-rendering: crisp-edges;
    }
}

.product-card {
    color: white;
    margin: 2px;
    display: flex;
    flex-direction: column;
}

.product-card > hr {
    margin: 0;
}

.product-card .product-name {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Roboto, serif;
    padding: 0 2px;
    font-size: 10px;
    text-align: center;
    flex: 1;
    margin: 4px 0 8px 0;
}

#product-detail-name {
    text-align: center;
}

#modal-product-detail-image, #product-detail-image {
    image-rendering: pixelated;
    margin-right: 0.1em;
    height: 2em;
}

.mobile-screen-info-popup-button {
    visibility: hidden;
    border-radius: 50%;
    z-index: 10;
    position: absolute;
    top: 5px;
    right: 5px;
    padding: 5px;
    font-size: 10px;
    line-height: 10px;
}

.product-card .product-cart-button-wrapper {
    text-align: center;
    margin-bottom: 4px;
    flex: 0 0 0;
}

.product-card > .product-cart-button-wrapper > button {

}
.product-card > .product-cart-button-wrapper > button svg {
    font-size: 13px !important;
}

.product-card > .product-cart-button-wrapper > button:hover {
    background-color: yellow;
}

.product-card > ul {
    display: None;
    list-style: None;
    margin: 0;
    padding: 8px 0 8px 0;
    flex: 1 0 0;
    font-size: 14px;
    color: lightgray;
}

.category-heading {
    width: 100%;
    display: flex;
    color: white;
    background-color: #1a1a1a;
    margin: 0;
    transition: 0.5s;
}

.category-heading > h5 {
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 40px;
    margin: 0;
    height: 40px;
    flex: 0 0 300px;
    text-align: center;
    background-color: #141414;
    clip-path: polygon(0 0, 100% 0, 90% 100%, 0 100%);
    font-family: 'Roboto', sans-serif;
    text-transform: uppercase;
}

.category-heading > h5 > svg {
    height: 25px;
    margin-right: 0.5em;
}

.category-heading > p {
    flex-grow: 1;
    margin: 0;
    padding: 8px;
    text-align: right;
}

.item-info-editable-block {
    display: flex;
}

.item-info-editable-block > label {
    flex: 0 0 30%;
}

.sp-coin-image {
    height: 1em;
}

#product-detail-info {
    width: 100%;
    flex: 1 0 0;
    flex-wrap: wrap;
    padding: 16px;
    background: #333;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

#product-detail-info::-webkit-scrollbar {
    display: none;
}

#available-servers, #modal-available-servers {
    display: flex;
}

#modal-available-servers > span, #available-servers > span {
    margin: 4px;
    background-color: #111;
    flex: 1;
    padding: 2px 6px;
}

.starcoin-in-text {
    height: 1em;
    width: 1em
}

#product-detail-items {
    flex: 1 0 150px;
    flex-direction: column;
    display: flex;
    padding-right: 8px;
    text-align: left;
    color: darkgray;
    font-size: 14px;
}

#product-detail-items > hr {
    width: 100%;
    background-color: white;
}

.product-info-detail {
    text-align: center;
    color: white;
}

#product-detail-items > ul > li > span {
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    line-height: 2em;
    height: 2em;
    width: 2em;
    background-color: #333;
    border-radius: 40px;
}

#product-detail-info::-webkit-scrollbar {
    display: none;
}

#product-detail-items ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

#product-detail-items > h5 {
    color: white;
    font-family: 'Roboto', sans-serif;
    font-weight: 600;
    text-align: center;
}

#product-detail-items > h5 > i {
    margin-right: 0.5em;
}

#product-detail-meta {
    flex: 1 0 200px;
    margin-left: 0.2em;
}

#product-detail-meta > h5 {
    text-align: center;
    font-family: 'Roboto', sans-serif;
    text-transform: uppercase;
    font-weight: 600;
}

#buying-confirmation-product-image {
    image-rendering: pixelated;
    width: 50%;
    text-align: center;
}

#confirmation-body {
    display: flex;
}

#confirmation-body > div {
    flex: 1 0 0;
    text-align: center;
}

img[src='/static/starcoin-min.png'] {
    filter: drop-shadow(1px 1px 3px blue);
}

#buying-information {
    color: darkgray;
    font-family: Roboto, serif;
    font-size: 11px;
}

#modal-product-content-description, #product-detail-description {
    text-align: center;
    color: white;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    padding: 0 2em;
}

#permission-product-list img {
    image-rendering: unset;
}
#item-product-list img {
    image-rendering: pixelated;
}

.item-count {
    color: gold;
    margin-right: 0.25em;
    margin-left: 0.25em;
    text-decoration: underline;
}

#cart {
    padding: 12px 16px;
    display: flex;
    background-color: #222;
}

#cart > #cart-products {
    display: grid;
    grid-template-columns: repeat(3, 33.333333%);
    flex: 1 0 0;
    min-height: 30vh;
    flex-wrap: wrap;
    align-content: flex-start;
}

#cart > #cart-products > .product-card {
    flex: 1 0 50px;
}

#cart > #cart-products > .product-card h6 {
    font-size: 50%;
}

#cart > #cart-converting-arrow {
    flex: 0 0 25px;
    align-items: center;
    display: flex;
}

#cart > #cart-converting-arrow > i {
    margin: auto;
}

#cart > #cart-items {
    flex: 1 0 0;
}

.modal-content {
    color: white;
    background: #333;
}

.cart-buttons {
    text-align: center;
    padding: 4px;
    background-color: #222;
}

#cart-items, #cart-items-modal {
    padding: 8px 0 0 8px;
    list-style: none;
}

#cart-items > li > img, #cart-items-modal > li > img {
    height: 1em;
    margin-right: 0.25em;
}

.product-price {
    text-align: center;
    font-size: 50%;
}
.modal-content {
    color: white;
    background: #333;
}

@media screen and (min-width: 1630px) and (max-width: 1930px) {
    .product-list {
        display: grid;
        grid-template-columns: repeat(10, 10%);
        padding: 4px;
        width: 100%;
    }

    #product-list::-webkit-scrollbar {
        display: none;
    }
}

@media screen and (min-width: 721px) and (max-width: 1125px) {
    .product-list {
        grid-template-columns: repeat(5, 20%);
    }

    #product-list::-webkit-scrollbar {
        display: none;
    }
}

@media screen and (max-width: 720px) {
    #items-list{
        height: unset;
    }
    #balance-toast {
        width: 100%;
    }

    #item-detail {
        display: none;
    }

    .product-card-selected > .mobile-screen-info-popup-button {
        visibility: visible;
    }

    .product-card-selected > .mobile-screen-info-popup-button:hover {
        background-color: rgba(255, 255, 255, 0.3);
    }

    #servers-filter {
        display: flex;
    }

    #product-detail-info {
        position: relative !important;
    }

    .product-image-wrapper > img {
        filter: none;
    }

    .product-list {
        display: grid;
        grid-template-columns: repeat(30, 31%);
        padding: 4px;
        width: 100%;
    }

    .product-list::-webkit-scrollbar {
        display: none;
    }

    .category-heading > h5 {
        flex: 1;
        clip-path: none;
    }

    #item-detail {
        margin: 0;
        min-height: auto;
    }

    img[src='/static/starcoin-min.png'] {
        filter: none;
    }

    #items-management {
        display: block;
    }
}
