#main-downloading-section {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 4rem 0;
}

#main-downloading-section > img {
    width: 150px;
    height: 150px;
    filter: drop-shadow(2px 4px 6px black)
}

.platform-artifacts {
    width: 100%;
    text-align: center;
}
.platform-artifacts > div {
    margin-bottom: 3rem;
}
.platform-artifacts > div > h4 {
    margin: 0;
    color: #ddd;
}
.platform-artifacts > div > p {
    color: lightgray;
    font-family: Roboto, serif;
    font-size: 14px;
}
.platform-artifacts > div > a {
    font-size: 14px;
    font-family: Roboto, serif;
    text-transform: uppercase;
    color: lightgoldenrodyellow;
}
.platform-artifacts > div > a > img {
    height: 1rem;
    width: 1rem;
    margin: 0 0.5rem;
}

#main-downloading-section-explanation {
    padding: 0 5em !important;
    text-align: center;
    color: white;
    font-family: Roboto, serif;
    font-size: 16px;
}
#requires-java-8-explanation {
    margin-top: 1em;
    padding: 0 5em !important;
    text-align: center;
    color: white;
    font-family: Roboto, serif;
    font-size: 12px;
}
#show-instructions-button {
    text-transform: uppercase;
    color: lightgoldenrodyellow;
    font-size: 14px;
    text-align: center;
    transition: 0.25s;
    cursor: pointer;
    text-decoration: underline  ;
    font-family: Roboto, serif;
}
#show-instructions-button:hover {
    color: gold;
}

#main-downloading-section-explanation > span {
    color: gold;
    text-decoration: underline;
    cursor: pointer;
}

#explanation-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #111;
    border: 2px solid #000;
    padding: 1em 2em;
}
#explanation-body {
    text-align: left;
}
#explanation-body li {
    margin-bottom: 0.75rem;
}
#close-icon {
    transition: 0.2s;
    cursor: pointer;
}
#close-icon:hover {
    color: gold;
}
#os-specific-downloading-section {
    display: flex;
    min-height: 300px;
    gap: 3rem;
}
.os-specific-download-section {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    margin-top: 2rem;
}
.os-specific-download-section > img {
    width: 100px;
}
.os-specific-download-section button {
    color: white;
    font-family: Roboto, serif;
}
.os-specific-section-span {
    font-family: Roboto, serif;
    text-align: center;
    color: #999;
}