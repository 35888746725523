body {
  margin: 0 !important;
  padding: 0 !important;
  overflow-y: scroll;
}
.hexagon {
  clip-path: polygon(25% 7%, 75% 7%, 100% 50%, 75% 93%, 25% 93%, 0% 50%);
}
.square {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 100%;
}

.loader-transition-exit div {
  opacity: 0;
}
.loader-transition-exit p {
  opacity: 0;
}
.loader-transition-exit img {
  opacity: 0;
}

.loader-transition-exit-active div {
  opacity: 100%;
  transition: ease-in-out 300ms;
}
.loader-transition-exit-active p {
  opacity: 100%;
  transition: ease-in-out 300ms;
}
.loader-transition-exit-active img {
  opacity: 100%;
  transition: ease-in-out 300ms;
}

.starshine-header {
  width: 100%;
  background: #111;
  color: white;
  text-align: center;
  font-family: Roboto, serif;
}
* {
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;    /* Firefox, other Gecko */
  box-sizing: border-box;
}
img {
  vertical-align: middle;
  border-style: none;
}
@font-face {
  font-family: 'Audiowide';
  src:  url('fonts/Audiowide-Regular.ttf');
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'Term Cyr';
  src:  url('fonts/term_cyr.ttf');
  font-style: normal;
  font-display: fallback;
}


#wrapper-404, #wrapper-500 {
  height: 100vh;
  width: 100vw;
  background: #222;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
#wrapper-404 > img, #wrapper-500 > img {
  width: 250px;
  height: 250px;
  border-radius: 50%;
  overflow: hidden;
}
#wrapper-404 > h5, #wrapper-500 > h5 {
  color: white;
  margin-top: 1em;
  font-family: Roboto, serif;
}
#wrapper-404 > h6, #wrapper-500 > h6 {
  color: gray;
  font-size: 13px;
  margin-top: 1em;
  font-family: Roboto, serif;
  text-align: center;
  max-width: 400px;
}