.no-style-link {
  text-decoration: none;
  color: white;
}
.small-link {
  font-size: 13px;
  color: lightblue;
}
.small-link:hover {
  color: whitesmoke;
}
.navigation-button-desktop {
  color: white !important;
  display: block;
  font-size: 14px !important;
  font-family: Roboto, serif;
}
.navigation-button-mobile {
  font-family: Roboto, serif;
}
.navigation-button-mobile a {
  color: white !important;
}
#balance-container {
  cursor: pointer;
  padding: 4px 8px;
  -webkit-appearance: none; color: #ffa726; text-decoration: underline;  font-family: Audiowide, serif;
  display: flex;
  align-items: center;
}
#brand {
  font-family: 'Audiowide', sans-serif !important;
}
#avatar-button {
  padding: 4px;
  transition: 0.4s;
  border-radius: 5px;
  align-self: center;
}
#avatar-button:hover {
  background: #555;
}
#nav-logo {
  font-family: 'Bebas Neue', serif !important;
  font-weight: bold;
  font-size: 26px !important;
  display: flex;
  color: aliceblue;
  text-decoration: none;
}
#nav-logo > img {
  height: 2em; width: 2em; border-radius: 50%;
}
#nav-logo > span {
  line-height: 2em;
  margin-left: 0.5em;
  font-family: Audiowide, serif;
}


