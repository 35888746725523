.main-heading-separator {
  font-family: 'Roboto', sans-serif;
  text-transform: uppercase;
  background-color: #1b1b1b;
  padding: 16px 0;
  color: white;
  text-align: center;
  margin: 0;
}
#about-rules p {
  font-family: 'Roboto', sans-serif;
  text-align: center;
}
#about-rules {
  margin-bottom: 0 !important;
}
#project-logo-wrapper {
  overflow: hidden;
  border-radius: 50%;
  flex: 4;
}
#project-logo-wrapper > img {
  border-radius: 61%;
  width: 100%;
}
#project-vanilla-server {
  margin: 2em auto;
  display: flex;
  width: 20%;
}
#project-vanilla-server p {
  flex: 1;
  margin: auto;
  width: 40%;
}

#project-logos {
  margin: 2em auto;
  display: flex;
  width: 25%;
}
#project-logos p {
  flex: 1;
  margin: auto;
  width: 40%;
}
#project-story {
  font-family: Roboto, serif;
  text-align: center;
  width: 30%;
  margin: 0 auto 0 auto;
  padding: 0 0 2em 0;
  color: #ddd !important;
}
#vanilla-description {
  font-family: Roboto, serif;
  text-align: center;
  margin: 1em auto 0 auto;
  padding: 0 0 2em 0;
  color: #ddd !important;
}
.vanilla-address {
  font-family: Roboto, serif !important;
  padding: 4px 8px;
  background: #111;
  border-radius: 10px;
  color: palegoldenrod;
}
.vanilla-address span {
  font-family: Roboto, serif !important;
}
.vanilla-mods {
  flex: 1;
  text-align: right;
}
.vanilla-core {
  flex: 1;
}
.vanilla-core-item {

}
.vanilla-core-item > span {
  font-family: Roboto, serif !important;
  font-size: 14px;
  text-align: center;
  color: white;
  font-weight: 100;
}
.vanilla-core-item > img {
  width: 1.25em;
  height: 1.25em;
  margin-bottom: 8px;
  margin-right: 8px;
  margin-left: 8px;
}
@media screen and (max-width: 1280px) {
  #project-story {
    width: 60%;
  }
}
@media screen and (max-width: 720px) {
  #project-logos {
    width: 70%;
  }
  #project-story {
    width: 80%;
  }
  #project-description {
    width: 90%;
    margin: 0 auto 1em auto;
  }
}
