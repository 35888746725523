#confirmation-buttons {
    text-align: center;
}
#confirmation-buttons > button {
    margin: 0 auto;
}
#close-modal {
    cursor: pointer;
}
#close-modal:hover {
    background: #333;
}