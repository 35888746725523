#rules{
    background: #2f2f2f;
    color: #f1ebeb;
    width: 100%;
    font-family: 'Roboto', serif;
    font-weight: lighter;
}
#about-rules{
    text-align: center;
}
#sidebar-element {
    vertical-align: top;
}
#unit-rules{
    font-size: 24px;
    background: #1b1b1b;
    padding: 10px;
    margin-top: 0;
    margin-bottom: 0;
    font-family: 'Roboto', serif;
    font-weight: lighter;
}
#rules-project-logo{
    width: 150px;
    margin: 1em auto;
}
#rules-project-logo img {
    border-radius: 50%;
    overflow: hidden;
    width: 100%;
}
#rules-description{
    width: 40%;
    margin: auto;
    line-height: 1.5;
    margin-bottom: 1em;
}
.setting-up-wrapper{
    width: 80%;
    margin: auto;
    background: #222222;
}
.setting-up-wrapper{
    padding: 10px;
}
.sp-heading{
    text-align: center;
    font-size: 24px;
    margin: 10px;
    font-family: 'Roboto', serif;
    font-weight: lighter;
}
.badge-warning{
    background: #ffc107;
    color: black;
    padding: 2px 4px;
    border-radius: 5px;
    line-height: 1.5;
}
.rules-instruction > ul {
    list-style: none;
}
.rules-instruction > ul > li {
    margin-bottom: 1em;
}

@media screen and (max-width: 720px) {
    #rules-description{
        width: 90%;
    }
    .setting-up-wrapper{
        width: 100%;
    }
}