#coins-buying-buttons {
    display: flex;
}
#coins-buying-buttons > span {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}
#coins-buying-buttons > span > a {
    color: lightblue;
    text-decoration: underline;
}
#coins-buying-buttons > span > a:hover {
    color: aliceblue;
}
.modal-agreement-paragraph {
    font-style: italic;
    color: darkgray;
    font-size: 14px;
    padding-bottom: 8px;
}

a {
    color: white;
    text-decoration: underline;
    font-size: 16px;
}