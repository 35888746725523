#server-background-preview {
    min-height: 45vh;
    /*-webkit-clip-path: polygon(0% 0%, 100% 0%, 50% 100%);*/
    /*clip-path: polygon(0% 0%, 100% 0%, 100% 70%, 50% 100%, 0% 70%);*/
    transition: 0.5s ease-in-out;
    display: flex;
    flex-direction: column;
    justify-content: center;

    background-size: cover;
}
.server-statistics {
    width: 70%;
    margin: auto;
}
#server-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
#server-info > h2, #server-info > h6 {
    font-family: 'Audiowide', sans-serif !important;
    text-shadow: #000000 10px 0 10px;
}


.server-info-wrapper {
    height: 100%;
    width: 100%;
    display: block;
    color: white;
}

.server-info-element {
    color: white;
    background-color: #222;
    margin-top: 0.5%;
    margin-left: auto;
    margin-right: auto;
    width: 70%;
}

.server-info-main-desc {
    padding: 1em;
    display: flex;
    flex-wrap: wrap;
}

#server-photo-album {
    height: 500px;
    flex-basis: 250px;
    flex-grow: 5;
    flex-shrink: 5;
}


#server-information {
    background: #101010;
    flex-basis: 200px;
    flex-grow: 2;
    flex-shrink: 2;
}

.server-info-main-desc-content-inner {
    padding: 1em;
    position: relative;
}

.server-info-main-desc-content-inner > h1 {
    text-align: center;
    font-family: Ouroboros, fantasy;
}

.server-info-main-desc-content-inner > p {
    font-size: 14px;
}

.server-info-online > h1 {
    text-align: center;
    font-family: Ouroboros fantasy;
}

.server-info-mods > li {
    margin-top: 1.5em;
}

.server-info-mods > h2 {
    text-align: center;
    font-family: Ouroboros fantasy;
    margin: 0;
}

.carousel-item > img {
    height: 100%;
    min-width: 100%;
    width: auto !important;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.carousel-inner {
    position: relative;
    height: 100%;
}

.carousel-item {
    height: 100%;
    overflow: hidden;
}

#photos-loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

h2 {
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    margin: 0;
    position: relative;
}

.mods-wrapper {
    display: flex;
    flex-wrap: wrap;
}

.mod-entity {
    padding: 3px;
    display: flex;
}

.mod-entity > img {
    flex-basis: 2em;
    width: 2.5em;
    height: 2.5em;
    image-rendering: pixelated;
    margin-left: .5em;
    margin-right: 1em;
}
.mod-entity > h6 {
    flex: 1;
    margin: 0;
    display: flex;
    align-items: center;
}
.mod-entity > h6 > span {
    font-size: 10px;
    font-family: Roboto, serif;
}

.mod-list {
    flex-basis: 220px;
    flex-grow: 1;
    background-color: #181818;
    margin: 0.5% 0.5% 1em;
}

.mod-list ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.mod-list ul h5 {
    margin-top: 0;
    text-align: center;
    font-family: Roboto, serif;
    color: gold;
    background-color: #111;
    padding-top: 1em;
    padding-bottom: 1em;
    margin-bottom: 10px;
}

.server-info-element h2 {
    text-align: center;
    font-family: Roboto, serif;
}

@media screen and (max-width: 720px) {
    .server-info-element {
        width: 100%;
    }

    .server-info-main-desc {
        padding: 1px;
    }

    #server-photo-album {
        height: 300px;
    }

    .server-info-img-inner {
        display: inline-block;
        width: 100%;
    }

    #server-photo-album {
        height: 300px;
        flex-basis: 250px;
        flex-grow: 5;
    }

    .carousel-item {
        object-fit: cover;
        vertical-align: middle;
    }
}
@media screen and (max-width: 1920px) {
    #server-background-preview {
        min-height: 85vh;
        /*clip-path: polygon(0% 0%, 100% 0%, 100% 80%, 50% 100%, 0% 80%);*/
    }
}
@media screen and (max-width: 1280px) {
    #server-background-preview {
        /*clip-path: polygon(0% 0%, 100% 0%, 100% 70%, 50% 100%, 0% 70%);*/
    }
}
@media screen and (max-width: 720px) {
    #server-background-preview {
        min-height: 25vh;
        /*clip-path: polygon(0% 0%, 100% 0%, 100% 80%, 50% 100%, 0% 80%);*/
    }
}