.server-card {
  background-color: #232323 !important;
  border-radius: 40px !important;
  padding: 8px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  transition: 0.3s;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
}
.see-details-link {
  cursor: pointer;
  text-decoration: underline;
  transition: 0.3s;
}
.server-card:hover .see-details-link {
  color: gold !important;
}

.server-card-transition-exit .server-card {
  opacity: 0;
}

.server-card-transition-exit-active .server-card {
  opacity: 100%;
  transition: ease-in-out 1000ms;
}

.server-card:hover {
  background-color: #2a2a2a !important;
}
.server-card-hex-content {
  display: flex;
  margin: 1em 0.1em;
  min-height: 100px;
}
.server-card__secondary-hexagon-wrapper {
  flex: 1;
  position: relative;
}
.server-card__primary-hexagon-wrapper {
  flex: 3;
}
.server-card__image {
  background: #222222;
  position: relative;
}
.server-card__version__wrapper {
  position: relative;
}
.server-card__version,
.server-card__online {
  background: #222222;
  color: whitesmoke;
  text-align: center;
  transition: 0.3s;
}
.server-card__version {
  right: -13px;
  font-size: 70%;
  position: absolute;
  top: 0;
}
.server-card__online {
  font-size: 70%;
  left: -13px;
  position: absolute;
  bottom: 0;
}
.server-card__version > span,
.server-card__online > span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.server-card__version:hover {
  background: #282828;
}
.server-card__online__wrapper {
  width: 40px;
  position: relative;
}
.server-card__title {
  text-align: center;
  color: whitesmoke;
  margin: 0;
  font-family: 'Audiowide', sans-serif !important;
}

.server-card__image img {
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.server-card__mod-list {
  position: relative;
  width: 90%;
  max-height: 120px;
  overflow-y: scroll;
  margin: 0 auto 0.5em auto;
}
.server-card__mod-list div {
  margin: 4px 0;
  position: relative;
}
.server-card__mod-list::-webkit-scrollbar {
  /*display: None;*/
  /*scrollbar-width: thin;*/
  opacity: 0.2;
  width: 5px;
}
.server-card__mod-list::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
.section::-webkit-scrollbar-track {
  background-color: darkgrey;
}
/*noinspection CssInvalidPropertyValue*/
.server-card__mod-list > div > img {
  margin: 0 8px;
  width: 2em;
  height: 2em;
  image-rendering: -webkit-optimize-contrast;
  vertical-align: middle;
}
.server-card__mod-list > div > span {
  font-family: Roboto, serif;
  color: #bbb !important;
  font-size: 10px;
  padding-top: 0 !important;
  margin-bottom: 0 !important;
  vertical-align: top;
  position: absolute;
  top: 50%;
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
  transform: translateY(-50%);
}
@media screen and (max-width: 1280px) {
  .server-card__mod-list > div > img {
    width: 1.4em;
    height: 1.4em;
    margin: 0 8px 0 3px;
  }
  .server-card__mod-list {
    max-height: 90px;
  }
  .server-card__mod-list > div > span {
    font-size: 12px;
  }
}
@media screen and (max-width: 720px) {
  .server-card {
    border-radius: 0 !important;
    margin-left: 0;
    margin-right: 0;
  }
  .server-card__mod-list > div > img {
    width: 2em;
    height: 2em;
    margin: 0 1em 0 3px;
  }
  .server-card__mod-list {
    max-height: 130px;
  }
  .server-card__mod-list > div > span {
    font-size: 14px;
  }
}
