#avatar-image {
  background: silver;
  width: 160px;
  height: 160px;
  overflow: hidden;
  border-radius: 20px;
  margin: 0 auto 10px auto;
}
#avatar-image > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
#profile-username {
  color: white;
  text-align: center;
  font-family: 'Audiowide', sans-serif !important;
  font-weight: lighter;
}
#profile-date-joined, #profile-total-playtime{
  color: whitesmoke;
  text-align: center;
  width: 100%;
  font-family: 'Roboto', serif;
}
#server-playing-status {
  width: 800px;
  height: 400px;
  margin: auto;
  position: relative;
}

.starshine-transition-exit div {
  opacity: 0;
}
.starshine-transition-exit h5 {
  opacity: 0;
}
.starshine-transition-exit img {
  opacity: 0;
}

.starshine-transition-exit-active div {
  opacity: 100%;
  transition: ease-in-out 300ms;
}
.starshine-transition-exit-active h5 {
  opacity: 100%;
  transition: ease-in-out 300ms;
}
.starshine-transition-exit-active img {
  opacity: 100%;
  transition: ease-in-out 300ms;
}
