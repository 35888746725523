#discord-sync-wrapper {
    min-height: 80vh;
    width: 100vw;
    background-color: #222;
    display: flex;
    justify-content: center;
    align-items: center;
}
#discord-sync {
    position: relative;
    padding: 3em 1em;
    background: #111;
    overflow: hidden;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
#discord-sync * {
    transition: 0.4s ease-in-out;
}

#discord-sync h5 {
    color: white;
    font-family: Roboto, serif;
}