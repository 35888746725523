#avatar-image {
    width: 160px;
    height: 160px;
    position: relative;
    overflow: hidden;
    border-radius: 20px;
    background: #1d1d26;
    margin-left: auto;
    margin-right: auto;
}
#nav-logo {
    display: flex;
    color: aliceblue;
    text-decoration: none
}
#nav-logo-settings {
    display: flex;
    align-items: center;
    color: aliceblue;
    text-decoration: none;
    transition: 0.3s;
    padding: 0 8px;
}
#nav-logo-settings > img {
    height: 1.5em;
    width: 1.5em;
    border-radius: 50%;
}
#nav-logo-settings > span {
    line-height: 2em;
    margin-left: 0.5em;
}
#nav-logo-settings:hover {
    background: #444444;
}
#avatar-image > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: 0.5s;
}
#avatar-setting {
    text-align: center;
}
