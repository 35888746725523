.group-list-item {
    background: #222;
    cursor: pointer;

}
.group-list-item:hover {
    background: #333;
}

.group-row {
    transition: 0.3s ease-in-out;
    cursor: pointer;
}

.group-row:hover {
    background: #333;
}

.smallInputOverride > input {
    height: 25px !important;
}