#payments-list {
    min-height: 90vh;
    position: relative;
}

#giving-button {
    margin: 0 auto;
}
#giving-button[disabled], #giving-button[disabled=true] {
    background-color: #F5F5F5;
}
#givingModal .modal-content {
    background: #333;
    color: white;
}
.modal-header > button > span {
    color: white !important;
}
.server {
    text-align: center;
}

#giving-body {
    display: flex;
    position: relative;
    min-height: 200px;
}

#giving-body .server {
    flex: 1;
}

#giving-body > .server > .hexagon:hover {
    background: #444;
    cursor: pointer;
}
#giving-body .server > .hexagon:hover {
    background-color: palegoldenrod;
}
#giving-item-count {
    color: gold;
    text-decoration: underline;
    margin: 0 0.4em
}
#giving-item-image {
    background-color: #222;
}

#giving-body .server > .hexagon-active {
    background: gold;
}

#giving-body p {
    text-align: center;
    margin-bottom: 0;
}

#giving-body img {
    width: 70%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

#giving-body .hexagon {
    position: relative;
    width: 100px;
    height: 100px;
    background: #1f1f1f;
    font-size: 2em;
    clip-path: polygon(25% 7%, 75% 7%, 100% 50%, 75% 93%, 25% 93%, 0% 50%);
    margin: auto;
}

#giving-body .inner-hexagon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 85%;
    height: 85%;
    background: #222;
}
#inventory-list {
    position: relative;
    min-height: 500px;
}
#inventory-list-inner {
    display: grid;
    grid-template-columns: repeat(8, 12.5%);
    width: 100%;
}

.item-inventory-card {
    transition: 0.5s;
    background-color: #333;
    margin-right: 4px;
    margin-bottom: 4px;
    flex-direction: column;
    display: flex;
}
.item-inventory-card:hover {
    background-color: #444;
}

.item-inventory-card > .item-image-wrapper {
    flex-basis: 0;
    background-color: #495057;
    margin: 6px;
}

.item-inventory-card > h6 {
    font-size: 11px;
    color: white;
    flex: 1;
    text-align: center;
    margin-top: 0;
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
}

.item-inventory-card > h6 > p {
    color: gold;
    text-decoration: underline;
    text-align: center;
}

.item-control {
    padding: 4px 12px 12px 12px;
    text-align: center;
}

.item-image-wrapper > img {
    image-rendering: pixelated;
    padding: 15%;
    width: 100%;
}

.item-inventory-card button {
    cursor: pointer;
    background-color: gold;
    border: none;
    padding: 4px 8px;
    border-radius: 6px;
    transition: 0.1s;
    font-size: 12px;
}
@media screen and (max-width: 1220px) {
    #inventory-list-inner {
        grid-template-columns: repeat(5, 20%);
    }
}

@media screen and (max-width: 720px) {
    #inventory-list-inner {
        grid-template-columns: repeat(3, 33.333%);
    }
}
